import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center",
    fontSize: "60%"
  }
}));

const Render = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <p>
        {" "}
        *Estimated value based on market value assumptions and is not an actual
        value. The value can fluctuate based on market conditions and company
        performance.{" "}
      </p>
    </div>
  );
};

export default Render;
