import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Auth, API } from "aws-amplify";
import { lighten, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { equityGroups } from "../../constants";
// import { CSVReader } from 'react-papaparse';
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
// import sleep from 'sleep-promise';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: "given_name",
    numeric: false,
    disablePadding: true,
    label: "First Name"
  },
  {
    id: "family_name",
    numeric: false,
    disablePadding: false,
    label: "Last Name"
  },
  { id: "eeId", numeric: true, disablePadding: false, label: "Employee ID #" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  { id: "group", numeric: true, disablePadding: false, label: "Group" }
  // { id: "admin", numeric: true, disablePadding: false, label: "Admin User" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, onDelete } = props;

  return (
    <div className={classes.main}>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            Users
          </Typography>
        )}

        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: "10%",
    marginRight: "10%",
    paddingTop: "2em"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  button: {
    alignItems: "flex-end",
    variant: "contained",
    color: "#black",
    backgroundColor: "#00add7",
    textTransform: "none",
    float: "right",
    margin: "1rem"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function Users() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [usersLoading, setUsersLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [fields, setFields] = React.useState({});
  const [validation, setValidation] = React.useState({});
  // const [importTotal, setImportTotal] = React.useState(0);
  // const [importCount, setImportCount] = React.useState(0);

  React.useEffect(() => {
    const param = search?.toLowerCase();
    let filteredUsers;
    if (param) {
      filteredUsers = users?.filter(u => {
        return (
          u?.firstName?.toLowerCase()?.includes(param) ||
          u?.lastName?.toLowerCase()?.includes(param) ||
          u?.email?.toLowerCase()?.includes(param)
        );
      });
    } else {
      filteredUsers = [...users];
    }
    setFilteredUsers(filteredUsers);
  }, [search, users]);

  React.useEffect(() => {
    listUsers()
      .then(r => {
        setUsers(r);
        setFilteredUsers(r);
        setUsersLoading(false);
      })
      .catch(e => {
        setUsersLoading(false);
        console.log(e);
      });
  }, []);

  const setField = (field, value) => {
    setFields({
      ...fields,
      [field]: value
    });
  };

  /*
  const randomPassword = () => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_-+=";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(
        Math.floor(Math.random() * chars.length)
      );
    }
    return password;
  }

  const csvError = (data) => {

  };
  const csvDrop = async (data) => {

    // aws rate limits sign ups, so we need to time these a bit more slowly
    let cnt = 0;
    setImportTotal(data.length);
    setImportCount(cnt);

    for (const record of data) {
      try {
        await Auth.signUp({
          username: record.data.email,
          password: randomPassword(),
          attributes: {
            email: record.data.email,
            given_name: record.data.given_name,
            family_name: record.data.family_name,
            "custom:eeId": record.data.employee_id,
            "custom:group": record.data.group
          }
        });

        let params = {
          body: {
            username: record.data.email,
            groupname: "users"
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`
          }
        };

        await API.post("AdminQueries", "/addUserToGroup", params);
        setImportCount(++cnt)
        await sleep(200);
      } catch (e) {
        console.error(e);
      }
    }
  };
  */

  async function listUsers() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    };
    let rest = await API.get("AdminQueries", "/listUsers?limit=60", myInit);
    let users = rest.Users;
    while (rest.NextToken) {
      rest = await API.get(
        "AdminQueries",
        `/listUsers?limit=60&token=${encodeURIComponent(rest.NextToken)}`,
        myInit
      );
      users = users.concat(rest.Users);
    }

    const ret = users
      .filter(val => val.Enabled === true)
      .map(val => {
        return {
          user: val,
          name: val.Username,
          isAdmin: val?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ]?.includes("admin"),
          ...val?.Attributes.reduce(
            (acc, it) => (
              // eslint-disable-next-line
              (acc[it.Name?.replace("custom:", "")] = it.Value), acc
            ),
            {}
          )
        };
      });

    // console.log("transformed : original", ret, rest);

    return ret;
  }

  const validate = () => {
    const validationErrors = {};

    if (!fields?.email) {
      validationErrors.email = "Email is required";
    }

    if (!fields?.firstName) {
      validationErrors.firstName = "First Name is required";
    }

    if (!fields?.lastName) {
      validationErrors.lastName = "First Name is required";
    }

    if (!fields?.group) {
      validationErrors.group = "Group is required";
    }

    if (!fields?.eeId) {
      validationErrors.eeId = "Employee id is required";
    }

    if (!fields?.password || fields?.password?.length < 8) {
      validationErrors.password =
        "Password is required and must be 8 characters.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidation(validationErrors);
      return false;
    }

    return true;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map(n => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, email) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = email => selected.indexOf(email) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  if (!filteredUsers) {
    return <CircularProgress disableShrink />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFields({});
    setValidation({});
    setOpen(false);
  };

  const handleSubmit = async () => {
    setUsersLoading(true);
    if (!validate()) {
      setUsersLoading(false);
      return;
    }

    try {
      await Auth.signUp({
        username: fields?.email,
        password: fields?.password,
        attributes: {
          email: fields?.email,
          given_name: fields?.firstName,
          family_name: fields?.lastName,
          "custom:eeId": fields?.eeId,
          "custom:group": fields?.group
        }
      });
    } catch (e) {
      setUsersLoading(false);
      setValidation({ error: e.message });
      console.log("error of this call:", e.message);
    }

    // let params = {
    //   body: {
    //     username: fields?.email
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `${(await Auth.currentSession())
    //       .getAccessToken()
    //       .getJwtToken()}`
    //   }
    // };

    // await API.post("AdminQueries", "/confirmUserSignUp", params);

    // add user to the correct group
    let params = {
      body: {
        username: fields?.email,
        groupname: fields?.isAdmin ? "admin" : "users"
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    };

    await API.post("AdminQueries", "/addUserToGroup", params);

    // for edit do this (will need to upodate the AdminQueries to call the updateUserAttributes):
    // let params = {
    //   body: {
    //     Username: 'd72c74bd-48ee-4e5b-bd41-4b6e1cd35f08',
    //     UserAttributes: [
    //      {
    //        Name: 'custom:group',
    //        Value: 'B'
    //      }
    //     ],
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `${(await Auth.currentSession())
    //       .getAccessToken()
    //       .getJwtToken()}`
    //   }
    // };
    // await API.post("AdminQueries", "/updateUserAttributes", params);
    // await API.get("AdminQueries", `/getUser?username=${encodeURIComponent('nick@sparklabs.us')}`, params);

    // update the list so the new user shows up
    const updatedUsers = await listUsers();
    setUsers(updatedUsers);
    setFields({});
    setValidation({});
    setOpen(false);
    setUsersLoading(false);
  };

  const handleDelete = async () => {
    setUsersLoading(true);
    for (const username of selected) {
      const params = {
        body: {
          username
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`
        }
      };

      await API.post("AdminQueries", "/disableUser", params);
    }

    const updatedUsers = await listUsers();
    setUsers(updatedUsers);
    setSelected([]);
    setUsersLoading(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {usersLoading ? (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div>
            {/*
            <CSVReader
              onDrop={csvDrop}
              onError={csvError}
              config={{
                header: true
              }}
            >
              <span>Drop CSV file here or click to import.</span>
            </CSVReader>
            */}
            <TextField
              required
              id="search"
              label="Search"
              fullWidth
              onChange={e => setSearch(e.target.value)}
              color="secondary"
            />
            <Button className={classes.button} onClick={handleClickOpen}>
              Add User
            </Button>
            <EnhancedTableToolbar
              numSelected={selected.length}
              onDelete={handleDelete}
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={users.length}
                />
                <TableBody>
                  {stableSort(filteredUsers, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.email);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={event => handleClick(event, row.email)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.email}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.given_name}
                          </TableCell>
                          <TableCell align="left">{row.family_name}</TableCell>
                          <TableCell align="right">{row.eeId}</TableCell>
                          <TableCell align="right">{row.email}</TableCell>
                          <TableCell align="right">{row.group}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={2} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={filteredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add User </DialogTitle>
        <DialogContent>
          {validation.error && (
            <p style={{ color: "red" }}>{validation.error}</p>
          )}
          <TextField
            required
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            error={validation?.firstName?.length > 0 ? true : false}
            helperText={validation?.firstName || ""}
            fullWidth
            onChange={e => setField("firstName", e.target.value)}
            color="secondary"
          />
          <TextField
            required
            autoFocus
            margin="dense"
            id="lastName"
            label="Last Name"
            error={validation?.lastName?.length > 0 ? true : false}
            helperText={validation?.lastName || ""}
            fullWidth
            onChange={e => setField("lastName", e.target.value)}
            color="secondary"
          />
          <TextField
            required
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            error={validation?.email?.length > 0 ? true : false}
            helperText={validation?.email || ""}
            fullWidth
            onChange={e => setField("email", e.target.value)}
            color="secondary"
          />
          <TextField
            required
            autoFocus
            margin="dense"
            id="password"
            label="Temporary Password"
            fullWidth
            type="password"
            error={validation?.password?.length > 0 ? true : false}
            helperText={validation?.password || ""}
            onChange={e => setField("password", e.target.value)}
            color="secondary"
          />
          <FormHelperText>Group *</FormHelperText>
          <Select
            required
            autoFocus
            margin="dense"
            id="group"
            label="Group"
            fullWidth
            value={fields?.group || ""}
            error={validation?.group?.length > 0 ? true : false}
            helperText={validation?.group || ""}
            onChange={e => setField("group", e.target.value)}
            color="secondary"
          >
            {Object.keys(equityGroups).map(g => (
              <MenuItem value={g}>{g}</MenuItem>
            ))}
          </Select>
          <TextField
            required
            autoFocus
            margin="dense"
            id="eeId"
            label="Employee Id"
            fullWidth
            error={validation?.eeId?.length > 0 ? true : false}
            helperText={validation?.eeId || ""}
            onChange={e => setField("eeId", e.target.value)}
            color="secondary"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={fields?.isAdmin}
                onChange={e => setField("isAdmin", e.target.checked)}
                name="isAdmin"
                color="primary"
              />
            }
            label="Admin User"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            backgroundColor="secondary"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            backgroundColor="secondary"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
