import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import {
  AppBar,
  Box,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from "@material-ui/core";
import { navigate } from "@reach/router";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        navigate(props.href);
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  main: {
    marginLeft: "8%",
    marginRight: "8%"
  },
  typography: {
    marginLeft: "10%",
    marginRight: "10%",
    color: "white"
  },
  logo: {
    maxHeight: "160px",
    maxWidth: "400px"
  },
  link: {
    underline: "none"
  },
  tabs: {
    fontSize: "18px",
    fontWeight: 530,
    textTransform: "none",
    color: "secondary",
    "&:hover, &:active, &:focus, &$selected": {
      color: "#fff",
      fontSize: "18px",
      fontWeight: 530,
      textTransform: "none"
    }
  },
  menuTabs: {
    fontSize: "16px",
    fontWeight: 530,
    textTransform: "none",
    color: "black",
    alignItems: "flex-start"
  },
  selected: {},
  panel: {
    backgroundColor: "#00add7",
    height: 60,
    paddingLeft: 75
  },
  appbar: {
    boxShadow: "none"
  }
}));

export default function Appbar() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(u => {
        u.isAdmin = u?.signInUserSession?.accessToken?.payload[
          "cognito:groups"
        ]?.includes("admin");
        setUser(u);
        console.log("app bar user:", u);
      })
      .catch(err => console.log(err));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.main}>
          <img src="/logoFinal.svg" alt="logo" className={classes.logo} />
          <Grid
            container
            justify="flex-start"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs"
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    background: "#ffffff",
                    height: "3px"
                  }
                }}
              >
                <LinkTab
                  label="Home"
                  href="/home"
                  {...a11yProps(0)}
                  className={classes.tabs}
                  classes={{
                    tabs: classes.tabs,
                    selected: classes.selected
                  }}
                />
                <LinkTab
                  label="History"
                  href="/history"
                  {...a11yProps(1)}
                  className={classes.tabs}
                  classes={{
                    tabs: classes.tabs,
                    selected: classes.selected
                  }}
                />
                <LinkTab
                  label="Plan Documents"
                  href="/document"
                  {...a11yProps(2)}
                  className={classes.tabs}
                  classes={{
                    tabs: classes.tabs,
                    selected: classes.selected
                  }}
                />
                {user?.isAdmin && (
                  <LinkTab
                    label="Admin"
                    className={classes.tabs}
                    classes={{
                      //tabs: classes.tabs,
                      selected: classes.selected
                    }}
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={event => {
                      event.stopPropagation();
                      handleMenu(event);
                    }}
                    color="inherit"
                    {...a11yProps(3)}
                  />
                )}
                <Menu
                  id="menu-admin-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  value={value}
                  onChange={handleChange}
                  aria-label="nav tabs"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <MenuItem onClick={() => handleClose("/admin/equity")}>
                    <LinkTab
                      label="Equity Entry"
                      className={classes.menuTabs}
                      component="button"
                      href="/admin/equity"
                      style={{ textDecoration: "none" }}
                      alignItems="flex-start"
                      {...a11yProps(3)}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("/admin/users")}>
                    <LinkTab
                      label="Users"
                      className={classes.menuTabs}
                      component="button"
                      href="/admin/users"
                      style={{ textDecoration: "none" }}
                      alignItems="flex-start"
                      {...a11yProps(3)}
                    />
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("/admin/reporting")}>
                    <LinkTab
                      label="Reporting"
                      className={classes.menuTabs}
                      component="button"
                      href="/admin/reporting"
                      style={{ textDecoration: "none" }}
                      alignItems="flex-start"
                      {...a11yProps(3)}
                    />
                  </MenuItem>
                </Menu>
              </Tabs>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.panel}>
        <TabPanel value={value} index={0}>
          <Typography className={classes.typography} variant="h6">
            Home
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography className={classes.typography} variant="h6">
            History
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography className={classes.typography} variant="h6">
            Plan Documents
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Typography className={classes.typography} variant="h6">
            Admin
          </Typography>
        </TabPanel>
      </div>
    </div>
  );
}
