import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  Tab,
  Typography,
  TextField,
  InputAdornment
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { navigate } from "@reach/router";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#00add7"
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
    paddingTop: "2em",
    backgroundColor: "#fffffff"
  },
  image: {
    width: "125px",
    height: "125px",
    marginBottom: "2em",
    marginTop: "2em"
  },
  button: {
    maxWidth: 500,
    height: 65
  },
  buttonText: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 600,
    color: "#00add7"
  },
  icon: {
    color: "#00add7"
  },
  commonText: {
    fontSize: "18px",
    fontWeight: 600
  },
  input: {
    paddingTop: "2em",
    paddingBottom: "2em"
  },
  tab: {
    paddingTop: "2em",
    paddingBottom: "2em",
    fontWeight: 600,
    color: "#ffffff",
    textTransform: "none"
  }
}));

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        navigate(props.href);
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function Login() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} align="center" direction="column">
          <Grid item>
            <img
              src="./loginIcon.png"
              alt="loginImg"
              className={classes.image}
            />
          </Grid>
          <Grid item>
            <form noValidate autoComplete="off">
              <Typography className={classes.commonText}>
                Forgot your password?
              </Typography>
              <div>
                <TextField
                  id="email-password"
                  placeholder="Enter email"
                  variant="standard"
                  fullWidth="true"
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon className={classes.icon} />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </form>
          </Grid>
        </Grid>
      </Paper>
      <div>
        <Grid container spacing={2} align="center" direction="column">
          <Button
            justify="center"
            className={classes.button}
            size="large"
            fullWidth="true"
            variant="contained"
            color="secondary"
            alignItems="center"
          >
            <Typography variant="button" className={classes.buttonText}>
              Email temporary password
            </Typography>
          </Button>
        </Grid>
      </div>
      <div className={classes.root}>
        <Grid container spacing={2} align="center" direction="column">
          <LinkTab
            justify="center"
            label="Go back to login screen"
            href="/"
            className={classes.tab}
          />
        </Grid>
      </div>
    </div>
  );
}
