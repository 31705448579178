import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Auth, API } from "aws-amplify";
import { Paper, Grid, Typography } from "@material-ui/core";
import EquityChart from "../components/charts/Equity";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  main: {
    paddingTop: "2em",
    marginLeft: "10%",
    marginRight: "10%"
  },
  paper: {
    padding: "10rem"
  },
  chart: {
    padding: theme.spacing(2)
  },
  icon: {
    maxHeight: "40px"
  },
  container: {
    paddingBottom: "2em"
  },
  eeInfo: {
    marginLeft: "2em",
    paddingBottom: "2em"
  }
}));

export default function History() {
  const classes = useStyles();
  const [user, setUser] = React.useState(null);
  const [equity, setEquity] = React.useState({});

  React.useEffect(() => {
    (async () => {
      const u = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const eqs = await API.get("equity", "/equity");
      const current = eqs.reduce(function(acc, val) {
        return Date.parse(acc.date) > Date.parse(val.date) ? acc : val;
      });
      setEquity(current);
      setUser(u);
    })();
  }, []);

  return (
    <div className={classes.main}>
      <Grid container className={classes.eeInfo} spacing={1} direction="column">
        <Grid item xs={4}>
          <Typography>
            Name:{" "}
            {`${user?.attributes?.given_name || ""} ${user?.attributes
              ?.family_name || ""}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Employee ID: {`${user?.attributes["custom:eeId"] || "N/A"}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justify="center"
        className={classes.container}
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <EquityChart />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="space-around">
        <Typography variant="body2">
          Last Updated -{" "}
          {equity?.date ? moment(equity.date).format("MM/DD/YYYY") : ""}
        </Typography>
      </Grid>
    </div>
  );
}
