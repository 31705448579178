import React, { Component, Fragment } from "react";
import { Header, Footer, AppBar, Sign } from "./components/Layouts";
import { Router } from "@reach/router";
import moment from "moment";
// import axios from "axios";
import Home from "./pages/home";
import History from "./pages/history";
import Document from "./pages/document";
import EquityEntry from "./pages/admin/equityEntry";
import Users from "./pages/admin/users";
import Reporting from "./pages/admin/reporting";
import Profile from "./pages/account/profile";
import Login from "./pages/login/login";
import Password from "./pages/login/password";
import Amplify, { Auth, API } from "aws-amplify";
import {
  withAuthenticator,
  AmplifyTheme,
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  VerifyContact
} from "aws-amplify-react";
import config from "./aws-exports";
import UserProvider from "./contexts/userProvider";
import CustomSignIn from "./components/auth/CustomSignIn";
import { equityGroups } from "./constants";

Amplify.configure(config);

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      signUrl: ""
    };
  }

  async componentDidMount() {
    console.log("Mounting the App.js!!!!");

    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    const contract = {
      id: 0,
      signedDate: ""
    };
    if (!user?.attributes["custom:dateSigned"]) {
      if (user?.attributes["custom:signatureContractId"]) {
        try {
          const resp = await API.get(
            "AdminQueries",
            "/contract/" +
              encodeURIComponent(
                user?.attributes["custom:signatureContractId"]
              ),
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`
              }
            }
          );

          const contractData = resp?.data;
          const signEvent = contractData.signers[0]?.events?.find(
            row => row.event === "sign_contract"
          );

          console.log(
            "get contract response:",
            contractData,
            contractData?.signers[0]?.sign_page_url,
            signEvent
          );

          contract.id = resp?.data?.id || "";
          contract.signedDate = signEvent?.timestamp || "";
          if (resp?.data?.status !== "signed") {
            this.setState({ signUrl: resp?.data?.signers[0]?.sign_page_url });
          }
        } catch (e) {
          console.log(
            "get contract response error:",
            e?.response,
            e?.response?.data
          );
        }
      } else {
        try {
          const resp = await API.post("AdminQueries", "/contract/", {
            body: {
              template_id: "e6900212-067a-401d-bce1-ab471654ea32",
              signers: [
                {
                  name: `${user?.attributes?.given_name} ${user?.attributes?.family_name}`,
                  email: user?.attributes?.email,
                  embedded_sign_page: "yes",
                  embedded_redirect_iframe_only: "yes",
                  skip_signature_request: "yes"
                }
              ],
              api_fields: [
                {
                  api_key: "name",
                  value: `${user?.attributes?.given_name} ${user?.attributes?.family_name}`
                },
                {
                  api_key: "shares",
                  value:
                    parseInt(equityGroups[user?.attributes["custom:group"]]) ||
                    0
                },
                {
                  api_key: "eeId",
                  value: user?.attributes["custom:eeId"] || ""
                },
                {
                  api_key: "date",
                  value: moment().format("MM/DD/YYYY")
                }
              ]
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`
            }
          });

          const contractData = resp?.data?.contract;

          console.log(
            "POST contract response:",
            contractData,
            contractData?.signers[0]?.sign_page_url
          );

          contract.id = contractData.id || "";
          this.setState({ signUrl: contractData?.signers[0]?.sign_page_url });
        } catch (e) {
          console.log("signature response error:", e);
        }
      }
    }

    try {
      let data = {};
      if (contract?.id) {
        data = {
          "custom:signatureContractId": contract?.id || "",
          "custom:dateSigned": contract?.signedDate || "",
          "custom:lastLoginDate": moment().format("MM/D/YYYY HH:mm:ss")
        };
      } else {
        data = {
          "custom:lastLoginDate": moment().format("MM/D/YYYY HH:mm:ss")
        };
      }

      await Auth.updateUserAttributes(user, data);
    } catch (e) {
      console.log("failed to update user: ", e.message);
    }
  }

  render() {
    const { signUrl } = this.state;
    return (
      <UserProvider>
        <Fragment>
          <Header />
          <AppBar />
          {signUrl && <Sign signUrl={this.state.signUrl} />}
          <Router>
            <Home default path="/" />
            <History path="/history" />
            <Document path="/document" />
            <EquityEntry path="/admin/equity" />
            <Users path="/admin/users" />
            <Reporting path="/admin/reporting" />
            <Profile path="/account/profile" />
            <Login path="/login" />
            <Password path="/password" />
          </Router>
          <Footer />
        </Fragment>
      </UserProvider>
    );
  }
}

// should move this to its own file!!!
const MyTheme = {
  container: {
    padding: 0,
    backgroundColor: "#00add7"
  },
  formContainer: {
    textAlign: "center",
    padding: "5em 0 30em 0"
  },
  sectionHeader: {
    backgroundColor: "primary",
    textAlign: "center",
    fontWeight: 550,
    marginTop: "1em",
    marginBottom: "2em"
  },
  formField: { marginBottom: "2em" },
  hint: {
    marginTop: "2em",
    textAlign: "center"
  },
  sectionFooter: {
    fullWidth: "true",
    color: "#00add7",
    backgroundColor: "black",
    height: "50px"
  },
  sectionFooterPrimaryContent: {
    alignItems: "center"
  },
  sectionFooterSecondaryContent: {
    display: "block",
    margin: "1rem 9rem",
    color: "white"
  },
  a: {
    color: "#ffffff",
    cursor: "pointer",
    fontWeight: 600
  },
  button: {
    color: "#00add7",
    backgroundColor: "black",
    size: "large",
    height: "50px",
    width: "398px",
    variant: "contained",
    fontSize: "20px",
    fontWeight: 600
  }
};

const options = {
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
    signUpFields: [
      { label: "Name", key: "name", required: true, type: "string" }
    ]
  }
};

export default withAuthenticator(
  App,
  false,
  [
    <CustomSignIn />,
    <ForgotPassword />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <ConfirmSignUp />,
    <RequireNewPassword />
  ],
  null,
  { ...AmplifyTheme, ...MyTheme },
  options
);
