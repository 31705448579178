// A custom theme for this app
export const equityGroups = {
  G80: 80,
  G20: 20,
  G15: 15,
  G10: 10,
  G7: 7,
  G3: 3,
  G2: 2
};
