import React from "react";
import { Auth, API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  CircularProgress,
  Divider,
  Box,
  Grid,
  Typography
} from "@material-ui/core";
// import { UserContext } from "../contexts/userProvider";
import { equityGroups } from "../constants";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  main: {
    marginLeft: "10%",
    marginRight: "10%"
  },
  root: {
    paddingTop: "2em"
  },
  paper: {
    height: 175,
    width: 400
  },
  chart: {
    flexBasis: "auto",
    maxWidth: "none"
  },
  icon: {
    maxHeight: "60px",
    maxWidth: "60px"
  },
  container: {
    height: 175,
    width: 400,
    paddingBottom: "2em",
    marginLeft: "1em"
  },
  divider: {
    position: "relative",
    height: "7em",
    marginTop: "3em",
    background: "#00add7"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "#00add7",
  m: 2,
  border: 2,
  padding: "1em"
};

export default function Home() {
  const classes = useStyles();
  // const userC = useContext(UserContext);
  // console.log("why is this empty context user:", userC);
  const [user, setUser] = React.useState(null);
  const [equity, setEquity] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const u = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setUser(u);

      const eqs = await API.get("equity", "/equity");
      const current = eqs.reduce(function(acc, val) {
        return Date.parse(acc.date) > Date.parse(val.date) ? acc : val;
      });
      setEquity(current);
      setLoading(false);
    })();
  }, []);

  const csepUnits =
    parseInt(equityGroups[user?.attributes["custom:group"]]) || 0;
  const csepValue = csepUnits && equity?.value ? parseFloat(equity?.value) : 0;
  const csepTotal = parseFloat(csepValue * csepUnits);

  return (
    <div className={classes.main}>
      {loading ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={1} direction="row" justify="space-around">
            <Grid item xs={3}>
              <Typography>
                Name:{" "}
                {`${user?.attributes?.given_name || ""} ${user?.attributes
                  ?.family_name || ""}`}
              </Typography>
              <Typography>
                Employee ID: {`${user?.attributes["custom:eeId"] || "N/A"} `}
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography align="right">
                Last Updated -{" "}
                {equity?.date ? moment(equity.date).format("MM/DD/YYYY") : ""}
              </Typography>
            </Grid>
          </Grid>
          <Box justify="center" borderRadius={16} {...defaultProps}>
            <Grid container spacing={1} justify="space-around">
              <Grid item xs={3} className={classes.chart}>
                <Typography align="center">
                  <img
                    src="/starIcon.png"
                    alt="star-icon"
                    className={classes.icon}
                  />
                </Typography>
                <Typography variant="h6" align="center">
                  CSEP Units
                </Typography>
                <Typography variant="h4" align="center" color="primary">
                  {csepUnits || "N/A"}
                </Typography>
              </Grid>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <Grid item xs={3} className={classes.chart}>
                <Typography align="center">
                  <img
                    src="/dollarIcon.png"
                    alt="dollar-icon"
                    className={classes.icon}
                  />
                </Typography>
                <Typography variant="h6" align="center">
                  Estimated* CSEP Value Per Unit
                </Typography>
                <Typography variant="h4" align="center" color="primary">
                  {csepValue ? `$${csepValue.toFixed(2)}` : "N/A"}
                </Typography>
              </Grid>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <Grid item xs={3} className={classes.chart}>
                <Typography align="center">
                  <img
                    src="/envelopeIcon.png"
                    alt="envelope-icon"
                    //className={classes.icon}
                  />
                </Typography>
                <Typography variant="h6" align="center">
                  Estimated* CSEP Total Value
                </Typography>
                <Typography variant="h4" align="center" color="primary">
                  ${csepTotal.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </div>
  );
}
