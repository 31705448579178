import React from "react";
import { SignIn } from "aws-amplify-react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Typography,
  TextField,
  InputAdornment
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";

const classes = {
  root: {
    flexGrow: 1,
    backgroundColor: "#00add7",
    paddingTop: "2rem",
    paddingBottom: "60rem"
  },
  paper: {
    padding: "2rem",
    margin: "auto",
    maxWidth: "39rem",
    backgroundColor: "#fffffff"
  },
  image: {
    marginBottom: "2em",
    marginTop: "2em",
    maxWidth: "30%"
  },
  button: {
    maxWidth: "39rem",
    height: 65,
    margin: "0 auto"
  },
  buttonText: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 600,
    color: "#00add7"
  },
  title: {
    textTransform: "uppercase",
    color: "#00add7",
    margin: 0
  },
  checkbox: {
    marginTop: "2em",
    marginBottom: "2em",
    textTransform: "none"
  },
  icon: {
    color: "#00add7"
  },
  input: {
    paddingTop: "2em"
  },
  tab: {
    paddingBottom: "2em",
    fontWeight: 600,
    color: "#ffffff",
    textTransform: "none",
    margin: "0 auto"
  }
};

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  showComponent(theme) {
    return (
      <div style={classes.root}>
        <Paper style={classes.paper}>
          <img src="./logoMain.png" alt="loginImg" style={classes.image} />
          <Grid container spacing={2} align="center" direction="column">
            <Grid item>
              <h1 style={classes.title}>Welcome</h1>
            </Grid>
            <Grid item>
              <form noValidate autoComplete="off" onSubmit={this.signIn}>
                <div>
                  <TextField
                    id="username"
                    placeholder="Enter email"
                    variant="standard"
                    fullWidth="true"
                    name="username"
                    style={classes.input}
                    onChange={this.handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle style={classes.icon} />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="password"
                    placeholder="Enter password"
                    variant="standard"
                    fullWidth="true"
                    type="password"
                    name="password"
                    style={classes.input}
                    onChange={this.handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon style={classes.icon} />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </form>
            </Grid>
          </Grid>
        </Paper>
        <div>
          <Grid container spacing={2} align="center" direction="column">
            <Button
              justify="center"
              style={classes.button}
              size="large"
              fullWidth="true"
              variant="contained"
              color="secondary"
              alignItems="center"
              onClick={this.signIn}
              disabled={!!this.state?.loading}
            >
              {this.state?.loading ? (
                <CircularProgress />
              ) : (
                <Typography variant="button" style={classes.buttonText}>
                  Login
                </Typography>
              )}
            </Button>
          </Grid>
        </div>
        <div style={classes.root}>
          <Grid container spacing={2} align="center" direction="column">
            <Tab
              component="a"
              justify="center"
              label="Forgot password?"
              onClick={() => this.changeState("forgotPassword")}
              style={classes.tab}
            />
          </Grid>
        </div>
      </div>
    );
  }
}

export default CustomSignIn;
