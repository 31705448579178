import React from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import { Auth } from "aws-amplify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";

export default function Sign(props) {
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(u => {
        setUser(u);
      })
      .catch(err => console.log(err));
  }, []);

  const handleSignout = event => {
    Auth.signOut();
    navigate("/");
  };

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
            <script src="https://cdncf.esignatures.io/assets/iframeResizer.4.2.10.min.js"></script>
            <iframe
              title="esignature-frame"
              src={props.signUrl}
              id="eSignaturesIOIframe"
              onload="iFrameResize({heightCalculationMethod: 'bodyScroll'}, '#eSignaturesIOIframe')"
              style={{ width: "1px", minWidth: "100%", minHeight: "20rem" }}
            ></iframe>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSignout} color="primary">
            Signout
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Signed Agreement
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Sign.propTypes = {
  signUrl: PropTypes.string.isRequired
};
