import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { equityGroups } from "../../constants";
import moment from "moment";

export default class Sales extends Component {
  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    const eqs = await API.get("equity", "/equity");

    const csepUnits =
      parseInt(equityGroups[user?.attributes["custom:group"]]) || 0;

    const data = eqs
      .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
      .map(equity => {
        const value = (csepUnits * parseFloat(equity.value)).toFixed(2);
        return {
          date: moment(equity.date).format("MM/DD/YYYY"),
          value
        };
      });

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create("EquityChart", am4charts.XYChart);

    // Add data
    chart.data = data;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.labels.template.adapter.add("dy", function(
      dy,
      target
    ) {
      if (target.dataItem && target.dataItem.index) {
        return dy + 25;
      }
      return dy;
    });

    // eslint-disable-next-line no-unused-vars
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "date";
    series.name = "Visits";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;

    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  render() {
    return (
      <div id="EquityChart" style={{ width: "100%", height: "500px" }}></div>
    );
  }
}
