// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_user_files_s3_bucket: "smartcaredocs180514-dev",
  aws_user_files_s3_bucket_region: "us-west-2",
  aws_dynamodb_all_tables_region: "us-west-2",
  aws_dynamodb_table_schemas: [
    {
      tableName: "documents-dev",
      region: "us-west-2"
    },
    {
      tableName: "equity-dev",
      region: "us-west-2"
    }
  ],
  aws_cognito_identity_pool_id:
    "us-west-2:04d64c39-5dc4-4dae-a5a2-113cc14f05a6",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_Hvdpazqgr",
  aws_user_pools_web_client_id: "jrgna1hdicbip2g2em1g1ps7r",
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint: "https://pwncycuza9.execute-api.us-west-2.amazonaws.com/dev",
      region: "us-west-2"
    },
    {
      name: "documents",
      endpoint: "https://xm31dht3mb.execute-api.us-west-2.amazonaws.com/dev",
      region: "us-west-2"
    },
    {
      name: "equity",
      endpoint: "https://fn62krb54k.execute-api.us-west-2.amazonaws.com/dev",
      region: "us-west-2"
    }
  ]
};

export default awsmobile;
