import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import MuiAlert from '@material-ui/lab/Alert';
import { Auth } from "aws-amplify";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
    marginTop: "2em"
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  button: {
    marginTop: "1em",
    textTransform: "none"
  }
}));

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

export default function Profile() {
  const classes = useStyles();
  const [fields, setFields] = React.useState({});
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(u => {
        setUser(u);
        // console.log('user:', u);
      })
      .catch(err => console.log(err));
  }, []);

  const setField = (field, value) => {
    setFields({
      ...fields,
      [field]: value
    });
  };

  const handleChangePassword = event => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, fields?.password, fields?.newPassword);
      })
      .then(data => setFields({ success: true }))
      .catch(err => {
        setFields({ error: err?.message });
        console.log("password update error: ", err.message);
      });

    // navigate("/");
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} align="center">
          <Grid item xs={12} container direction="column">
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1">
                Name:{" "}
                {`${user?.attributes?.given_name} ${user?.attributes?.family_name}`}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Employee ID: {`${user?.attributes["custom:eeId"] || ""} `}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                email: {`${user?.attributes?.email}`}
              </Typography>
            </Grid>
            <Grid item>
              <form className={classes.root} noValidate autoComplete="off">
                <div>
                  <h1>Update Password</h1>

                  {fields.success && (
                    <p style={{ color: "green" }}>
                      Your Password has been updated successfully!
                    </p>
                  )}
                  {fields.error && (
                    <p style={{ color: "red" }}>{fields.error}</p>
                  )}
                  <TextField
                    id="old-password"
                    placeholder="Old Password"
                    variant="outlined"
                    fullWidth="true"
                    type="password"
                    value={fields?.password}
                    onChange={e => setField("password", e.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    id="new-password"
                    placeholder="New Password"
                    variant="outlined"
                    fullWidth="true"
                    type="password"
                    value={fields?.newPassword}
                    onChange={e => setField("newPassword", e.target.value)}
                  />
                </div>
                <div>
                  <TextField
                    id="confirm-password"
                    placeholder="Confirm Password"
                    variant="outlined"
                    fullWidth="true"
                    type="password"
                    value={fields?.confirmPassword}
                    onChange={e => setField("confirmPassword", e.target.value)}
                  />
                </div>
                <div>
                  <Button
                    className={classes.button}
                    size="large"
                    fullWidth="true"
                    variant="contained"
                    color="primary"
                    onClick={handleChangePassword}
                  >
                    <Typography variant="button">Update</Typography>
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
