import React from "react";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import { AppBar, Grid, IconButton, Toolbar, Tab } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  main: {
    marginRight: "10%",
    marginLeft: "10%"
  },
  typography: {
    fontFamily: "Arial",
    textTransform: "none",
    minWidth: "auto",
    paddingTop: ".5rem",
    marginLeft: ".5rem",
    color: "black"
  },
  indicator: {
    backgroundColor: "#FF0",
    height: "40px"
  },
  appbar: {
    boxShadow: "none",
    maxHeight: "44px"
  }
}));

export default function Header() {
  const classes = useStyles();
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(u => {
        setUser(u);
      })
      .catch(err => console.log(err));
  }, []);

  const handleSignout = event => {
    Auth.signOut();
    navigate("/");
  };

  return (
    <div>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.main}>
          <Grid
            container
            className={classes.root}
            justify="flex-end"
            spacing={2}
            direction="row"
          >
            <div style={{ marginRight: ".5rem" }}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => navigate("/account/profile")}
                color="inherit"
              >
                <AccountCircle />
                <span style={{ marginLeft: ".5rem", fontSize: "14px" }}>
                  {`${user?.attributes?.given_name} ${user?.attributes?.family_name}`}
                </span>
              </IconButton>
              <Tab
                component="a"
                label="Logout "
                href="/"
                onClick={handleSignout}
                className={classes.typography}
              />
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
