import React from "react";
import PropTypes from "prop-types";
import { Auth, API } from "aws-amplify";
import { lighten, makeStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: "group", numeric: false, disablePadding: false, label: "Group" },
  {
    id: "employees",
    numeric: true,
    disablePadding: false,
    label: "# Employees"
  },
  {
    id: "percentage",
    numeric: true,
    disablePadding: false,
    label: "% Employee Login"
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.onSave();
    setOpen(false);
  };

  return (
    <div className={classes.main}>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Reporting
        </Typography>

        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" onClick={handleClickOpen}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Filter</DialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Start Date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="date"
                    fullWidth
                    value={props.filterValues?.startDate}
                    onChange={e =>
                      props.onSetFilter("startDate", e.target.value)
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="End Date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="date"
                    fullWidth
                    value={props.filterValues?.endDate}
                    onChange={e => props.onSetFilter("endDate", e.target.value)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                Filter Table
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Toolbar>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  main: {
    marginRight: "10%",
    marginLeft: "10%",
    paddingTop: "2em"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export default function Reporting() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [results, setResults] = React.useState([]);
  const [filters, setFilters] = React.useState({});

  const setFilter = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value
    });
  };

  React.useEffect(() => {
    (async () => {
      let myInit = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`
        }
      };
      const { ...rest } = await API.get("AdminQueries", "/listUsers", myInit);

      setResults(rest);
      createList(rest);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createList = response => {
    const list = [];

    if (filters?.startDate && filters?.endDate) {
      response.Users = response?.Users.filter(row => {
        const date = row?.Attributes?.find(
          row => row.Name === "custom:lastLoginDate"
        )?.Value;
        if (date) {
          return (
            Date.parse(date) >= Date.parse(filters.startDate) &&
            Date.parse(date) <= Date.parse(filters.endDate)
          );
        }

        return false;
      });
    }

    response.Users.reduce(function(res, value) {
      const groupId = value?.Attributes?.find(
        row => row.Name === "custom:group"
      )?.Value;
      if (!res[groupId]) {
        res[groupId] = {
          group: groupId,
          employee_count: 0,
          employee_login: 0
        };
        list.push(res[groupId]);
      }
      res[groupId].employee_count++;
      if (value?.Attributes?.find(row => row.Name === "custom:lastLoginDate")) {
        res[groupId].employee_login++;
      }
      return res;
    }, {});

    setUsers(list.sort((a, b) => a.group.localeCompare(b.group)));
    setLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilter = () => {
    createList({ ...results });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {loading ? (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div>
              <EnhancedTableToolbar
                filterValues={filters}
                onSetFilter={setFilter}
                onSave={handleFilter}
              />
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(users, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.group}
                            </TableCell>
                            <TableCell align="right" id={labelId}>
                              {row.employee_count}
                            </TableCell>
                            <TableCell align="right" id={labelId}>
                              {row.employee_count
                                ? parseInt(
                                    (row.employee_login / row.employee_count) *
                                      100
                                  )
                                : 0}
                              %
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}
