import AddCircleIcon from "@material-ui/icons/AddCircle";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import PublishIcon from "@material-ui/icons/Publish";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Auth, API, Storage } from "aws-amplify";
import { red, pink } from "@material-ui/core/colors";
import { lighten, makeStyles } from "@material-ui/core/styles";

function FormDialog({ user }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormName = ({ target }) => {
    setName(target.value);
  };

  const handleFileSelection = ({ target }) => {
    const files = target.files;
    if (files) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { key } = await Storage.put(file.name, file, {
      contentType: file.type
    });

    const body = {
      name: name,
      storagePath: key,
      createdAt: new Date().toISOString()
    };

    // eslint-disable-next-line no-unused-vars
    const doc = await API.post("documents", "/documents", { body });
    setOpen(false);
    setLoading(false);
    setName("");
    setFile({});
  };

  return (
    <div>
      {user?.isAdmin && (
        <AddCircleIcon
          style={{
            color: pink["A200"],
            height: "75px",
            width: "75px",
            cursor: "pointer",
            position: "relative",
            top: "-1.6em"
          }}
          onClick={handleClickOpen}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a document provide it a name and upload the file below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={handleFormName}
            required
          />
          <input
            color="primary"
            accept="application/pdf"
            type="file"
            onChange={handleFileSelection}
            id="icon-button-file"
            style={{ display: "none" }}
            required
          />
          <label htmlFor="icon-button-file">
            <Button
              variant="contained"
              component="span"
              size="large"
              color="primary"
            >
              <PublishIcon />
              <span>{file.name || "Select File"}</span>
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Upload
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}

const getComparator = (order, orderBy) => {
  const decCompare = (a, b, ob) => {
    return b[ob] < a[ob] ? -1 : b[ob] === a[ob] ? 1 : 0;
  };

  return order === "desc"
    ? (a, b) => decCompare(a, b, orderBy)
    : (a, b) => -decCompare(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  return array
    .map((el, idx) => [el, idx])
    .sort((a, b) => {
      const cmp = comparator(a[0], b[0]);
      return cmp !== 0 ? cmp : a[1] - b[1];
    })
    .map(el => el[0]);
};

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "date", numeric: true, disablePadding: false, label: "Date Uploaded" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  main: {
    marginLeft: "10%",
    marginRight: "10%"
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, selected } = props;

  const downloadDocuments = async () => {
    for (const doc of selected) {
      const downloadUrl = await Storage.get(doc.storagePath, { expires: 60 });
      window.open(downloadUrl);
    }
  };

  const deleteDocuments = async () => {
    for (const doc of selected) {
      await Promise.all([
        API.del("documents", `/documents/object/${doc.name}/${doc.createdAt}`),
        Storage.remove(doc.storagePath)
      ]);
    }
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Plan Documents
        </Typography>
      )}

      {numSelected > 0 && (
        <>
          <Tooltip title="Download">
            <IconButton aria-label="download" onClick={downloadDocuments}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          {props?.user?.isAdmin && (
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={deleteDocuments}>
                <DeleteIcon style={{ color: red[500] }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  main: {
    marginLeft: "10%",
    marginRight: "10%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function Document() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [documents, setDocuments] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [user, setUser] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const [documents, user] = await Promise.all([
        API.get("documents", "/documents"),
        Auth.currentAuthenticatedUser()
      ]);
      setDocuments(documents);
      user.isAdmin = user?.signInUserSession?.accessToken?.payload[
        "cognito:groups"
      ]?.includes("admin");
      setUser(user);
      console.log("USER", user);
      setLoading(false);
    })();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = documents;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, doc) => {
    const selectedIndex = selected.indexOf(doc);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, doc);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    console.warn(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = doc => selected.indexOf(doc) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, documents.length - page * rowsPerPage);

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <FormDialog user={user} />
        <Paper className={classes.paper}>
          {loading ? (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div>
              <EnhancedTableToolbar
                numSelected={selected.length}
                selected={selected}
                user={user}
              />
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={documents.length}
                  />
                  <TableBody>
                    {stableSort(documents, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((doc, index) => {
                        const isItemSelected = isSelected(doc);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={event => handleClick(event, doc)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={doc.name}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {doc.name}
                            </TableCell>
                            <TableCell align="right">{doc.createdAt}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={documents.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}
