import React from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import moment from "moment";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { red, pink } from "@material-ui/core/colors";
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.background,
    color: theme.palette.primary,
    fontSize: 16
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginRight: "10%",
    marginLeft: "10%"
  },
  main: {
    marginRight: "10%",
    marginLeft: "10%"
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    //<div className={classes.main}>
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
    //</div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const useStyles2 = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  main: {
    paddingTop: "2em",
    marginLeft: "10%",
    marginRight: "10%"
  },
  button: {
    alignItems: "flex-end",
    variant: "contained",
    color: "#black",
    backgroundColor: "#00add7",
    textTransform: "none",
    float: "right"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const useStyles3 = makeStyles({
  head: {
    padding: 14
  }
});

export default function EquityEntry() {
  const classes = useStyles2();
  const classesBase = useStyles3();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [fields, setFields] = React.useState({});
  const [validation, setValidation] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [equities, setEquities] = React.useState([]);

  const setField = (field, value) => {
    setFields({
      ...fields,
      [field]: value
    });
  };

  React.useEffect(() => {
    (() => {
      loadList();
    })();
  }, []);

  const loadList = async () => {
    setLoading(true);
    const eqs = await API.get("equity", "/equity");
    setEquities(eqs);
    setLoading(false);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, equities.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setFields({});
    setOpen(true);
  };

  const handleClose = () => {
    setFields({});
    setOpen(false);
  };

  const handleDelete = async eq => {
    await API.del(
      "equity",
      `/equity/object/${encodeURIComponent(eq.date)}/${encodeURIComponent(
        eq.createdAt
      )}`
    );
    loadList();
  };

  const handleSave = async () => {
    if (!validate()) {
      return;
    }
    let params = {
      body: {
        date: fields?.date,
        value: parseFloat(fields?.shareValue),
        createdBy: "Some Person",
        createdAt: moment().format("MM/D/YYYY HH:mm:ss")
      }
    };

    await API.post("equity", "/equity", params);
    handleClose();
    loadList();
  };

  const validate = () => {
    const validationErrors = {};

    if (!fields?.date) {
      validationErrors.date = "Date field is required";
    }

    if (!fields?.shareValue || isNaN(parseFloat(fields?.shareValue))) {
      validationErrors.shareValue =
        "Share Value is required and must be an amount.";
    }

    setValidation(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.main}>
      <AddCircleIcon
        style={{
          color: pink["A200"],
          height: "75px",
          width: "75px",
          cursor: "pointer",
          position: "relative",
          top: "-2.7em"
        }}
        onClick={handleClickOpen}
      />
      <Paper>
        {loading ? (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography
                  className={classesBase.head}
                  variant="h6"
                  id="tableTitle"
                >
                  Equity Entry
                </Typography>
              </Grid>
              <Grid item>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add Share Value{" "}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      To enter a new share value, enter the amount below and
                      click the 'Add' button.
                    </DialogContentText>
                    <TextField
                      autoFocus
                      required
                      margin="dense"
                      label="New share value"
                      type="share value"
                      id="standard-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      fullWidth
                      color="secondary"
                      error={validation?.shareValue?.length > 0 ? true : false}
                      helperText={validation?.shareValue || ""}
                      onChange={e => setField("shareValue", e.target.value)}
                    />
                    <TextField
                      required
                      id="date"
                      label="Date"
                      type="date"
                      error={validation?.date?.length > 0 ? true : false}
                      helperText={validation?.date || ""}
                      className={classes.textField}
                      onChange={e => setField("date", e.target.value)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      backgroundColor="secondary"
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSave}
                      backgroundColor="secondary"
                      color="primary"
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell align="right">Share Value</StyledTableCell>
                    <StyledTableCell align="right">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? equities.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : equities
                  ).map(row => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {moment(row.date).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell align="right">${row.value}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleDelete(row)}
                          aria-label="delete"
                          color="secondary"
                        >
                          <DeleteIcon style={{ color: red[500] }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 }
                      ]}
                      colSpan={3}
                      count={equities.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )}
      </Paper>
    </div>
  );
}
