import React, { Component } from "react";
import { Auth } from "aws-amplify";

export const UserContext = React.createContext({ value: "fuck" });

export default class CompleteProvider extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      user: {}
    };
  }

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    console.log("user provider user from state:", user);

    return (
      <UserContext.Provider user={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

CompleteProvider.contextType = UserContext;
